export default {
  created() {
    this.$toast = {
      success: (message, modal = false) => {
        this.$buefy.toast.open({
          message,
          type: "is-success",
          position: modal ? "is-top" : "is-bottom-right",
          duration: 5000
        });
      },
      error: (message, modal = false) => {
        this.$buefy.toast.open({
          message,
          type: "is-danger",
          position: modal ? "is-top" : "is-bottom-right",
          duration: 5000
        });
      }
    };
  }
};
