<template>
  <ValidationProvider
    :vid="vid"
    :name="$attrs.name || $attrs.label"
    :rules="rules"
    class="validation-provider field"
    v-slot="{ errors, valid }"
  >
    <b-field
      v-bind="$attrs"
      :type="{ 'is-danger': errors[0], 'is-success': valid }"
      :message="errors"
    >
      <b-input v-model="innerValue" v-bind="$attrs" />
    </b-field>
  </ValidationProvider>
</template>

<script>
import BField from "buefy/src/components/field/Field";
import BInput from "buefy/src/components/input/Input";
import {
  ValidationProvider,
  ValidationObserver
} from "vee-validate/dist/vee-validate.full";

export default {
  components: {
    ValidationProvider,
    // import all standard validation rules
    // eslint-disable-next-line vue/no-unused-components
    ValidationObserver,
    BInput,
    BField
  },
  props: {
    vid: {
      type: String
    },
    rules: {
      type: [Object, String],
      default: ""
    },
    // must be included in props
    value: {
      type: null
    }
  },
  data: () => ({
    innerValue: ""
  }),
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit("input", newVal);
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal;
    }
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  }
};
</script>

<style lang="scss" scoped>
.validation-provider {
  display: block;
}
</style>
