<template>
  <ValidationObserver ref="observer" v-slot="{ passes }">
    <!-- the "passes" function on the slot-scope only chains if the validation is successful -->
    <!-- Making it easier to call directly in the template than to call `passes` on the observer component -->
    <div>
      <h1 class="title is-5">Enter your one-time password</h1>

      <BInputWithValidation
        rules="required"
        type="text"
        label="Code"
        v-model="code"
      />
      <p class="field help is-danger" v-if="error">
        {{ error }}
      </p>
      <b-field>
        <p class="control">
          <button class="button is-success" @click="passes(submit)">
            <span class="icon">
              <font-awesome-icon icon="sign-in-alt" />
            </span>
            <span>Login</span>
          </button>
        </p>
      </b-field>
      <b-field>
        <p class="control">
          <button class="button" @click="cancel">
            <span>Sign in with a different account</span>
          </button>
        </p>
      </b-field>
    </div>
  </ValidationObserver>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faSignInAlt } from "@fortawesome/free-solid-svg-icons";
library.add(faSignInAlt);

import FontAwesomeIcon from "@fortawesome/vue-fontawesome/src/components/FontAwesomeIcon";
import BField from "buefy/src/components/field/Field";
import { ValidationObserver } from "vee-validate";
import { mapActions, mapState } from "vuex";
import BInputWithValidation from "./BInputWithValidation";

export default {
  name: "LoginOTPForm",
  components: {
    ValidationObserver,
    BInputWithValidation,
    BField,
    FontAwesomeIcon
  },
  props: {
    shouldFetchPatientOnLogin: {
      type: Boolean,
      required: true
    }
  },
  mounted() {},
  data() {
    return {
      code: "",
      submitted: false
    };
  },
  computed: {
    error() {
      return this.$store.error;
    },
    ...mapState({
      user_id: state => state.user_id,
      session_id: state => state.session_id
    })
  },
  methods: {
    ...mapActions(["OTPValidate", "logout"]),
    submit() {
      this.submitted = true;
      const { code, user_id, session_id, shouldFetchPatientOnLogin } = this;
      if (code) {
        this.OTPValidate({
          code,
          user_id,
          session_id,
          shouldFetchPatient: shouldFetchPatientOnLogin
        });
      }
    },
    cancel() {
      this.logout();
    }
  }
};
</script>

<style scoped></style>
