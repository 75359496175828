var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"stateWrapper",class:{ contentChanged: _vm.hasContentChanged }},[(_vm.content)?_c('div',{staticClass:"display-flex align-center gap-s-ppNew"},[_c('div',[_c('font-awesome-icon',{class:{
          'grey-100-ppNew': true,
          [_vm.content.iconColorClass]: !!_vm.content.iconColorClass,
          animateIcon: _vm.content.icon === 'check-circle'
        },attrs:{"icon":_vm.content.icon,"spin":_vm.content.icon === 'circle-notch',"size":"2x"}})],1),_c('div',[_c('div',{staticClass:"display-flex gap-3xs-ppNew text-m-semibold grey-100-ppNew"},[(_vm.totalAmount)?_c('div',[_vm._v(_vm._s(_vm._f("toUSD")(_vm.totalAmount)))]):_vm._e(),(_vm.totalAmount)?_c('div',[_vm._v("-")]):_vm._e(),_c('div',[_vm._v(_vm._s(_vm.content.heading))])]),(_vm.content.description)?_c('div',{staticClass:"text-m grey-70-ppNew description"},[_vm._v(" "+_vm._s(_vm.content.description)+" ")]):_vm._e()])]):_c('div',[_c('div',[_vm._v("Error. No response receieved")])]),(
      _vm.workflowResponse.workflow_status === 'created' ||
        _vm.workflowResponse.workflow_status === 'pending'
    )?_c('div',{staticClass:"cancelButtonContainer"},[_c('button',{class:{
        'text-m': true,
        'cancel-button': true,
        cancelling: _vm.isCancellingPosWorkflow || _vm.isWorkflowCancelled
      },on:{"click":_vm.onCancelClick}},[(_vm.isCancellingPosWorkflow || _vm.isWorkflowCancelled)?_c('span',{staticClass:"loader"}):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.isCancellingPosWorkflow || _vm.isWorkflowCancelled ? "Cancelling..." : "Cancel"))])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }