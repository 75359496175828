<script>
import { useCurrencyInput } from "vue-currency-input";
import { watch } from "vue";

export default {
  name: "CurrencyInput",
  props: {
    value: null
  },
  setup(props) {
    const { formattedValue, inputRef, setValue } = useCurrencyInput({
      currency: "USD",
      accountingSign: true,
      currencyDisplay: "narrowSymbol",
      hideCurrencySymbolOnFocus: false,
      hideGroupingSeparatorOnFocus: false,
      hideNegligibleDecimalDigitsOnFocus: false,
      precision: 2
    });

    watch(
      () => props.value,
      value => {
        setValue(value);
      }
    );

    return { inputRef, formattedValue };
  }
};
</script>

<template>
  <b-input
    ref="inputRef"
    :value="formattedValue"
    size="is-small"
    custom-class="currency-inputbase"
    expanded
    placeholder="$0.00"
  />
</template>

<!-- beware: not scoped -->
<style lang="scss">
.control input.currency-inputbase {
  border-radius: 4px;
}
</style>
