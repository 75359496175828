<template>
  <!-- the "passes" function on the slot-scope only chains if the validation is successful -->
  <!-- Making it easier to call directly in the template than to call `passes` on the observer component -->
  <div class="modal-container">
    <div class="modal-fullscreen">
      <div class="modal-content">
        <ValidationObserver ref="observer1" v-slot="{ passes }">
          <div class="modal-header">
            <div class="modal-title">
              Choose patient contact to send request
            </div>
          </div>

          <div class="modal-body">
            <b-field>
              <b-autocomplete
                :data="contacts"
                ref="contactAutocomplete"
                placeholder="Select Patient Contact"
                :custom-formatter="formatContact"
                :open-on-focus="true"
                @select="option => (selectedContact = option)"
              >
                <template slot-scope="props">
                  <div class="card-template">
                    <span>{{ props.option.phone }}</span>
                    <span>{{ props.option.email }}</span>
                  </div>
                </template>
              </b-autocomplete>
            </b-field>
          </div>

          <div class="modal-footer">
            <div v-if="error" class="block has-text-danger">
              {{ error }}
            </div>
            <div class="buttons">
              <button class="button is-primary" @click="passes(submit)">
                {{ "Request" }}
              </button>
              <button class="button is-danger" @click="cancel()">
                Cancel
              </button>
            </div>
          </div>
        </ValidationObserver>
      </div>
    </div>
    <div class="modal-backdrop" />
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faSignInAlt } from "@fortawesome/free-solid-svg-icons";
library.add(faSignInAlt);

import { mapActions, mapState } from "vuex";
import { ValidationObserver } from "vee-validate";
import BField from "buefy/src/components/field/Field";
import BAutocomplete from "buefy/src/components/autocomplete/Autocomplete";

export default {
  name: "RequestConsent",
  components: {
    ValidationObserver,
    BField,
    BAutocomplete
  },
  props: {
    onFinish: {
      type: Function
    }
  },
  data() {
    return {
      error: null,
      submitted: false,
      invalid: false,
      selectedContact: null
    };
  },
  computed: {
    ...mapState({
      patient: state => state.patient,
      contacts: state =>
        state.patient.emails.concat(state.patient.phones).filter(c => c.enabled)
    })
  },
  methods: {
    ...mapActions(["showRequestConsentDlg"]),
    submit() {
      if (this.selectedContact) {
        this.onFinish(this.selectedContact);
        this.showRequestConsentDlg(false);
      }
    },
    cancel() {
      this.onFinish(null);
      this.showRequestConsentDlg(false);
    },
    formatContact(c) {
      return c.phone ? c.phone : c.email;
    }
  }
};
</script>

<style scoped lang="scss"></style>
