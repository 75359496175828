import { activityStorage } from "@/services/activityStorage";

const MILLISECONDS_IN_SECOND = 1000;
const SECONDS_IN_MINUTE = 60;

const updateActivityTimestamp = () => {
  activityStorage.save(new Date());
};

export const startLogoutTimer = ({ timeoutPeriodMinutes, logoutUser }) => {
  if (!timeoutPeriodMinutes || typeof timeoutPeriodMinutes !== "number") {
    return {
      resetTimer: null
    };
  }
  const timeout =
    timeoutPeriodMinutes * SECONDS_IN_MINUTE * MILLISECONDS_IN_SECOND;
  let intervalId;

  const checkInactivity = () => {
    const lastActivity = activityStorage.get();
    if (lastActivity && new Date() - lastActivity > timeout) {
      activityStorage.clear();
      clearInterval(intervalId);
      logoutUser();
    }
  };

  const startInactivityTimer = () => {
    updateActivityTimestamp();
    intervalId = setInterval(checkInactivity, MILLISECONDS_IN_SECOND);
  };

  ["click", "keypress"].forEach(event => {
    window.addEventListener(event, updateActivityTimestamp, true);
  });

  startInactivityTimer();

  return {
    resetTimer: updateActivityTimestamp
  };
};
