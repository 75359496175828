const STORAGE_KEY = "last_activity_timestamp";

/**
 * Save a last activity time to localStorage.
 * @param {Date} date - The timestamp to save.
 */
const save = date => {
  try {
    localStorage.setItem(STORAGE_KEY, date.toISOString());
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
};

/**
 * Get the last activity date from localStorage.
 * @returns {Date|null} The saved timestamp or null if not found.
 */
const get = () => {
  try {
    const dateString = localStorage.getItem(STORAGE_KEY);
    return new Date(dateString);
  } catch {
    return null;
  }
};

/**
 * Clear the last activity date from localStorage.
 */
const clear = () => {
  try {
    localStorage.removeItem(STORAGE_KEY);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
};

export const activityStorage = {
  save,
  get,
  clear
};
