<template>
  <ValidationObserver
    tag="form"
    ref="observer"
    v-slot="{ passes }"
    @submit.prevent="submit"
  >
    <!-- the "passes" function on the slot-scope only chains if the validation is successful -->
    <!-- Making it easier to call directly in the template than to call `passes` on the observer component -->
    <div>
      <h1 class="title is-5">
        Please log in with your Collectly credentials
      </h1>

      <p>
        {{ currentUser }}
      </p>
      <BInputWithValidation
        rules="required|email"
        type="text"
        label="Email"
        v-model="email"
      />

      <BInputWithValidation
        rules="required"
        type="password"
        label="Password"
        vid="password"
        v-model="password"
      />

      <p class="field help is-danger" v-if="error">
        {{ error }}
      </p>
      <b-field>
        <p class="control">
          <button class="button is-success">
            <span class="icon">
              <font-awesome-icon icon="sign-in-alt" />
            </span>
            <span>Login</span>
          </button>
        </p>
      </b-field>
    </div>
  </ValidationObserver>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faSignInAlt } from "@fortawesome/free-solid-svg-icons";
library.add(faSignInAlt);

import { mapActions } from "vuex";
import { ValidationObserver } from "vee-validate";
import BInputWithValidation from "./BInputWithValidation";
import BField from "buefy/src/components/field/Field";
import FontAwesomeIcon from "@fortawesome/vue-fontawesome/src/components/FontAwesomeIcon";

export default {
  name: "LoginForm",
  components: {
    ValidationObserver,
    BInputWithValidation,
    BField,
    FontAwesomeIcon
  },
  props: {
    shouldFetchPatientOnLogin: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      email: "",
      password: "",
      submitted: false
    };
  },
  computed: {
    currentUser() {
      return this.$store.user;
    },
    error() {
      return this.$store.authError;
    }
  },
  methods: {
    ...mapActions(["login"]),
    submit() {
      this.submitted = true;
      const { email, password, shouldFetchPatientOnLogin } = this;
      if (email && password) {
        this.login({
          email,
          password,
          shouldFetchPatient: shouldFetchPatientOnLogin
        });
      }
    }
  }
};
</script>

<style scoped></style>
