<script>
export default {
  name: "PosPaymentState",
  props: {
    workflowResponse: null,
    isCancellingPosWorkflow: null,
    isWorkflowCancelled: null
  },
  data() {
    return {
      hasContentChanged: false,
      totalAmount: null
    };
  },
  watch: {
    content: {
      handler(curr, prev) {
        if (curr.heading === prev.heading) return;

        this.hasContentChanged = true;

        setTimeout(() => {
          this.hasContentChanged = false;
        }, 700);
      },
      deep: true
    }
  },
  computed: {
    content() {
      if (!this.workflowResponse) return null;

      if (
        this.workflowResponse.workflow_status === "created" ||
        this.workflowResponse.workflow_status === "pending"
      ) {
        return {
          icon: "circle-notch",
          iconColorClass: "blue-100-ppNew",
          heading: "Payment Processing",
          description: "Ask the patient to follow terminal prompts."
        };
      }

      if (this.workflowResponse.workflow_status === "success") {
        return {
          icon: "check-circle",
          iconColorClass: "green-100-ppNew",
          heading: "Payment Successful",
          description: "Patient completed the payment."
        };
      }

      if (this.workflowResponse.workflow_status === "cancelled") {
        return {
          icon: "ban",
          heading: "Payment Cancelled"
        };
      }

      if (this.workflowResponse.workflow_status === "error") {
        return {
          icon: "exclamation-circle",
          iconColorClass: "red-120-ppNew",
          heading: "Payment Error",
          description:
            (this.workflowResponse && this.workflowResponse.error_text) ||
            "An error occurred during payment processing."
        };
      }

      return null;
    }
  },
  methods: {
    onCancelClick() {
      this.$emit("cancelWorkflow");
    }
  },
  created() {
    const paymentStep = this.workflowResponse.workflow_steps.find(
      s => s.name === "pay-and-store-card"
    );

    if (!paymentStep || !paymentStep.amount) return;

    this.totalAmount = paymentStep.amount;
  }
};
</script>

<template>
  <div class="stateWrapper" :class="{ contentChanged: hasContentChanged }">
    <div v-if="content" class="display-flex align-center gap-s-ppNew">
      <div>
        <font-awesome-icon
          :icon="content.icon"
          :spin="content.icon === 'circle-notch'"
          size="2x"
          :class="{
            'grey-100-ppNew': true,
            [content.iconColorClass]: !!content.iconColorClass,
            animateIcon: content.icon === 'check-circle'
          }"
        ></font-awesome-icon>
      </div>

      <div>
        <div class="display-flex gap-3xs-ppNew text-m-semibold grey-100-ppNew">
          <div v-if="totalAmount">{{ totalAmount | toUSD }}</div>
          <div v-if="totalAmount">-</div>
          <div>{{ content.heading }}</div>
        </div>
        <div
          v-if="content.description"
          class="text-m grey-70-ppNew description"
        >
          {{ content.description }}
        </div>
      </div>
    </div>

    <div v-else>
      <div>Error. No response receieved</div>
    </div>

    <div
      v-if="
        workflowResponse.workflow_status === 'created' ||
          workflowResponse.workflow_status === 'pending'
      "
      class="cancelButtonContainer"
    >
      <button
        :class="{
          'text-m': true,
          'cancel-button': true,
          cancelling: isCancellingPosWorkflow || isWorkflowCancelled
        }"
        @click="onCancelClick"
      >
        <span
          v-if="isCancellingPosWorkflow || isWorkflowCancelled"
          class="loader"
        ></span>
        <span>{{
          isCancellingPosWorkflow || isWorkflowCancelled
            ? "Cancelling..."
            : "Cancel"
        }}</span>
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.stateWrapper {
  position: relative;
  flex: 1 0 auto;
  display: grid;
  place-items: center;
  z-index: 2;
}

.contentChanged {
  animation: contentChanged 0.5s forwards ease;
}

.description {
  max-width: 360px;
  word-wrap: break-word;
}

.cancelButtonContainer {
  position: absolute;
  bottom: 0;
  right: 0;
}

.cancel-button {
  display: flex;
  align-items: center;
  gap: $gap-2xs-ppNew;
  padding: $gap-3xs-ppNew $gap-xs-ppNew;
  border: 1px solid $blue-100;
  border-radius: 4px;
  font-weight: 500;
  transition: background-color 0.2s, color 0.2s;
  background-color: transparent;
  cursor: pointer;
  user-select: none;

  &.cancelling {
    border-color: $grey-10;
    background: $grey-10;
    color: $grey-100;
    pointer-events: none;

    .loader {
      border-color: $blue-100;
      border-right-color: transparent;
      border-top-color: transparent;
    }
  }

  &:not(.cancelling) {
    &:hover {
      background-color: $blue-100;
      color: white;
    }

    &:active {
      background-color: $blue-120;
    }
  }
}

.animateIcon {
  opacity: 0;
  transform: rotate(30deg) scale(0.65);
  animation: iconMove 0.75s ease forwards;
  animation-delay: 0.2s;
}

@keyframes iconMove {
  from {
    opacity: 0;
    transform: rotate(30deg) scale(0.65);
  }
  to {
    opacity: 1;
    transform: rotate(0deg) scale(1);
  }
}

@keyframes contentChanged {
  from {
    transform: translateY(-10px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
</style>
