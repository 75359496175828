<script>
import { mapActions, mapState } from "vuex";
import { webAppURL } from "@/constants";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCircleNotch,
  faTimes,
  faSync,
  faCheckCircle
} from "@fortawesome/free-solid-svg-icons";
import LoginForm from "./Login";
import LoginOTPForm from "./LoginOTP";

library.add(faSync);
library.add(faCircleNotch);
library.add(faTimes);
library.add(faCheckCircle);

export default {
  name: "ProductClaimConfirmationDlg",
  components: { LoginForm, LoginOTPForm, FontAwesomeIcon },
  data() {
    return {
      fetchingError: null,
      // decision | login | sync | paymentLink
      step: "decision",
      backofficePaymentLink: null
    };
  },
  watch: {
    isAuthenticating(newValue, oldValue) {
      if (newValue === false && oldValue === true) {
        this.step = "sync";
        this.handleSubmit();
      }
    }
  },
  computed: {
    ...mapState({
      productClaimCheckoutData: state => state.productClaimCheckoutData,
      currentUser: state => state.user,
      hasAuthError: state => state.status === "error",
      isOTPRequired: state =>
        state.status === "otp-required" ||
        state.status === "otp-required-error",
      isFetchingPatient: state => state.isFetchingPatient,
      isAuthenticating: state => state.isAuthenticating,
      isResyncingBalance: state => state.isResyncingBalance
    }),
    isLoading() {
      return (
        this.isFetchingPatient ||
        this.isAuthenticating ||
        this.isResyncingBalance
      );
    },
    textsForLoadingStates() {
      const texts = {
        heading: null,
        description: null
      };

      if (this.isFetchingPatient) {
        return texts;
      }

      if (this.isAuthenticating) {
        texts.heading = "Logging you in. Please wait";
        return texts;
      }

      if (this.isResyncingBalance) {
        texts.heading = "Preparing a payment link";
        texts.description = "This will take 10-20 seconds. Please wait";
        return texts;
      }

      return texts;
    }
  },
  methods: {
    ...mapActions(["showPayClaimDlg", "resyncBalanceForExternal"]),
    handleSubmit() {
      if (!this.currentUser) {
        this.step = "login";
        return;
      }

      this.backofficePaymentLink = null;
      this.fetchingError = null;

      /** @type {import('../types/product.types').TEcwProductCheckoutData} */
      const productClaimCheckoutData = this.productClaimCheckoutData;

      if (
        !productClaimCheckoutData ||
        !productClaimCheckoutData.externalPatientId
      ) {
        // eslint-disable-next-line no-console
        console.error(
          "Product claim checkout data isn't complete. Value:",
          productClaimCheckoutData
        );
        return;
      }

      this.resyncBalanceForExternal(productClaimCheckoutData.externalPatientId)
        .then(patientId => {
          const searchParams = new URLSearchParams();
          searchParams.set("action", "payment");
          searchParams.set(
            "productsTotal",
            productClaimCheckoutData.productsTotal
          );

          const url = `${webAppURL}/frontdesk/patient/${patientId}?${searchParams.toString()}`;

          this.backofficePaymentLink = url;
          this.step = "paymentLink";
        })
        .catch(error => {
          this.fetchingError = error.error_text || error.message;
          this.backofficePaymentLink = null;
        });
    }
  }
};
</script>

<template>
  <div class="wrapper">
    <div class="base display-flex align-center justify-center">
      <div class="close-icon-wrapper" @click="showPayClaimDlg(false)">
        <font-awesome-icon icon="times" size="1x"></font-awesome-icon>
      </div>

      <div v-if="!hasAuthError && !isLoading && step !== 'login'">
        <div v-if="!fetchingError && step === 'decision'">
          <div class="heading-s">Take payment with Collectly?</div>

          <div class="margin-top-m-ppNew">
            <div class="display-flex gap-m-ppNew justify-center">
              <button
                type="button"
                class="button is-primary text-s-medium"
                @click="handleSubmit"
              >
                YES
              </button>
              <button
                type="button"
                class="button text-s-medium"
                @click="showPayClaimDlg(false)"
              >
                NO
              </button>
            </div>
          </div>
        </div>

        <div v-if="step === 'paymentLink'" class="text-center">
          <font-awesome-icon
            icon="check-circle"
            size="2x"
            class="green-100-ppNew "
          ></font-awesome-icon>

          <div class="margin-top-xs-ppNew heading-s">
            Your payment link is ready
          </div>

          <div class="margin-top-xs-ppNew">
            <a
              :href="backofficePaymentLink"
              target="_blank"
              class="text-m-medium paymentLink"
            >
              Click here to take payment with Collectly
            </a>
          </div>
        </div>
      </div>

      <div v-if="isLoading" class="display-flex flex-column gap-s-ppNew">
        <font-awesome-icon
          icon="circle-notch"
          spin
          size="3x"
          class="blue-100-ppNew"
        ></font-awesome-icon>
        <div class="text-center">
          <div class="text-m-medium">
            {{ textsForLoadingStates.heading }}
          </div>
          <div class="margin-top-2xs-ppNew text-s">
            {{ textsForLoadingStates.description }}
          </div>
        </div>
      </div>

      <div
        v-if="!isLoading && !!fetchingError"
        class="has-text-danger text-center text-s"
      >
        <div>
          {{ fetchingError }}
        </div>

        <div class="margin-top-xs-ppNew">
          <button
            type="button"
            class="button is-primary text-s-medium"
            @click="handleSubmit"
            :disabled="isLoading"
          >
            Try again
          </button>
        </div>
      </div>

      <div v-if="!isLoading && (step === 'login' || hasAuthError)">
        <LoginForm
          v-if="!currentUser && !isOTPRequired"
          :shouldFetchPatientOnLogin="false"
        />
        <LoginOTPForm
          v-if="!currentUser && isOTPRequired"
          :shouldFetchPatientOnLogin="false"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.wrapper {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.7);
}

.base {
  $modalMinHeight: 190px;
  position: absolute;
  top: calc(50% - $modalMinHeight / 2);
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 48px;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
  min-width: 450px;
  min-height: $modalMinHeight;
}

.close-icon-wrapper {
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
  border-radius: 50%;
  padding: 5px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: $grey-100;
  background: $grey-5;
  transition: color 0.2s, background-color 0.2s;

  &:hover {
    color: $blue-100;
    background: $grey-10;
  }
}

.paymentLink {
  margin: 0;
}
</style>
