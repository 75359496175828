const USER_KEY = "collectly_user";

const save = user => {
  try {
    localStorage.setItem(USER_KEY, JSON.stringify(user));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
};

const get = () => {
  try {
    const user = localStorage.getItem(USER_KEY);
    return JSON.parse(user);
  } catch {
    return null;
  }
};

const clear = () => {
  try {
    localStorage.removeItem(USER_KEY);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
};

export const userStorage = {
  save,
  get,
  clear
};
