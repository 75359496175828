import * as Sentry from "@sentry/browser";
import * as Integrations from "@sentry/integrations";
import Buefy from "buefy";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { userStorage } from "@/services/userStorage";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://5f11be9b6aac40d7ad0df70bb79077e6@sentry.collectly.co/5",
    integrations: [new Integrations.Vue({ Vue, attachProps: true })]
  });
}

Vue.config.productionTip = false;

Vue.use(Buefy);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");

const user = userStorage.get();
if (user) {
  void store.dispatch("authSuccess", user);
}

window.addEventListener("message", event => {
  if (event.data.eventName === "collectlyExtensionLogout") {
    store.dispatch("logout");
  }
  // IMPORTANT: check the origin of the data!
  const isAllowedDomain = true;
  // TODO: Identify the closed list of eCW domains
  // const isAllowedDomain = allowedDomains.some(domain =>
  //   event.origin.includes(domain)
  // );

  if (!isAllowedDomain) {
    return;
  }

  if (event.data.eventName === "credentials") {
    store.dispatch("reloginResult", event.data);
  }

  if (event.data.eventName === "patientIdChanged") {
    if (
      store.state.practiceId !== event.data.practiceId ||
      store.state.departmentId !== event.data.departmentId ||
      store.state.connectionType !== event.data.connectionType ||
      store.state.username !== event.data.username
    ) {
      store.commit("practiceIdChanged", {
        practiceId: event.data.practiceId,
        departmentId: event.data.departmentId,
        connectionType: event.data.connectionType,
        username: event.data.username
      });
    }

    if (store.state.patientId === event.data.patientId) {
      return;
    }
    void store.dispatch("resetLogoutTimer");
    store.commit("patientIdChanged", event.data.patientId);

    if (event.data.patientId) {
      store
        .dispatch("getPatient", { patientExternalId: event.data.patientId })
        .then(
          () => {},
          errorPayload => {
            store.commit("apiError", errorPayload.error_text);
          }
        );
    }
  }

  if (event.data.eventName === "productClaimPaymentTriggered") {
    /** @type {import('./types/product.types').TEcwProductCheckoutData} */
    const checkoutData = event.data.checkoutData;

    store.dispatch("fetchPublicClientSettings").then(settings => {
      if (!settings || !settings.ecw_product_claims_payments_enabled) {
        return;
      }

      store.commit("setProductClaimCheckoutData", checkoutData);
      store.dispatch("showPayClaimDlg", true);
    });
  }
});

(() => {
  try {
    let urlParams = new URLSearchParams(window.location.search);
    let patientId = urlParams.get("patient_id");
    let practiceId = urlParams.get("practice_id");
    let departmentId = urlParams.get("department_id");
    let connectionType = urlParams.get("connection_type");
    let username = urlParams.get("username");
    if (!patientId) {
      return;
    }
    store.commit("practiceIdChanged", {
      practiceId: practiceId,
      departmentId: departmentId,
      username: username,
      connectionType: connectionType || "athenahealth"
    });
    store.commit("patientIdChanged", patientId);
    void store.dispatch("resetLogoutTimer");
    store.dispatch("getPatient", { patientExternalId: patientId });
  } catch (error) {
    // just ignore the error, this code is needed only for local development
  }
})();
