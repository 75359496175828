var consts = {
  stripeKey: window._env_.STRIPE_PK,
  webAppURL: window._env_.WEBAPP_URL,
  stripeOptions: {
    elements: {
      fonts: [
        {
          cssSrc:
            "https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap"
        }
      ]
    },
    style: {
      base: {
        lineHeight: "20px",
        fontFamily: "Inter",
        fontSize: "14px",
        color: "#484848",
        "::placeholder": {
          color: "#a7a7a7",
          opacity: 1
        }
      }
    }
  }
};

module.exports = consts;
