<template>
  <div class="root" :class="{ noIssues: !this.display_issue_blocks.length }">
    <div
      v-if="
        this.display_issue_blocks.length && this.display_issue_blocks.length > 0
      "
    >
      <div class="text-m-semibold">
        Remaining issues to resolve: {{ this.display_issue_blocks.length }}
      </div>

      <div class="issuesList margin-top-xs-ppNew">
        <div v-for="text in issueBlockTexts" :key="text" class="text-m">
          {{ text }}
        </div>

        <div
          v-if="shouldShowToggleBtn"
          @click="toggleIssueListDisplayMode()"
          class="text-m-semibold blue-100-ppNew toggleBtn"
        >
          {{ toggleButtonText }}
        </div>
      </div>

      <div class="display-flex margin-top-xs-ppNew">
        <a
          class="resolveLink text-s-semibold"
          :href="apptUrl"
          target="__blank"
          rel="noopener noreferrer"
        >
          Resolve issues in Collectly
        </a>
      </div>
    </div>

    <div v-else class="text-m">No issues to resolve</div>
  </div>
</template>

<script>
import { mapState } from "vuex";

const ISSUE_BLOCK_LABELS = {
  guarantor: "Guarantor data needs verification",
  patient: "Patient data needs verification",
  insurances: "Insurance missing or needs verification",
  payments: "Patient payment outstanding",
  payment_methods: "No card on file",
  emergency_contact: "Emergency contact missing",
  id_card: "Patient/guarantor ID card missing",
  medical_forms: "Medical forms missing",
  patient_consents: "Patient consent forms missing",
  primary_provider: "Provider missing or needs verification",
  referral_provider: "Referral missing or needs verification",
  pre_service_deposit: "Pre-service deposit not set"
};

export default {
  name: "AppointmentIssues",
  props: {
    display_issue_blocks: {
      type: Array,
      required: true
    },
    apptUrl: {
      type: String,
      required: true
    }
  },
  components: {},
  mounted() {
    // this.$store.getters.isFeatureEnabled("pre-visit-check-in")
  },
  data() {
    return {
      initiallyShownIssuesNumber: 4,
      allIssuesShown: false
    };
  },
  computed: {
    shouldShowToggleBtn() {
      return this.display_issue_blocks.length > this.initiallyShownIssuesNumber;
    },
    issueBlockTexts() {
      return (
        this.display_issue_blocks
          .map((issueBlock, index) => {
            if (
              !this.allIssuesShown &&
              this.initiallyShownIssuesNumber < index + 1
            ) {
              return null;
            }

            return ISSUE_BLOCK_LABELS[issueBlock];
          })
          .filter(Boolean) || []
      );
    },
    toggleButtonText() {
      return this.allIssuesShown
        ? "Show less"
        : `Show
        ${this.display_issue_blocks.length - this.initiallyShownIssuesNumber}
        more`;
    },
    ...mapState({
      allowRedirectToAppointment: state =>
        !state.user.hide_appointments || state.user.is_system
    })
  },
  methods: {
    toggleIssueListDisplayMode() {
      this.allIssuesShown = !this.allIssuesShown;
    }
  }
};
</script>

<style lang="scss" scoped>
.root {
  padding: 16px;
  border-radius: 8px;
  background: $red-10;

  &.noIssues {
    color: $grey-100;
    background: $grey-5;
  }
}

.issuesList {
  padding: 4px 0 4px 16px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  border-left: 3px solid $red-100;
}

.resolveLink {
  display: block;
  margin-left: auto;
}

.toggleBtn {
  cursor: pointer;
  transition: color 0.2s ease;

  &:hover {
    color: $blue-120;
  }
}
</style>
